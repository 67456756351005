<template>
    <div class="container">
        <div id="vue-leaflet"></div>
    </div>
</template>


<script>
import L from "leaflet"


export default {
    name: 'LeafletMap',
    data() {
        return {
            map: null
        }
    },
    mounted() {
        this.initMap()
    },
    methods: {
        // 使用id为vue-leaflet的div容器初始化地图
        initMap() {
            this.map = L.map("vue-leaflet", {
                center: [39.90562, 116.38840], // 中心位置 先纬度后经度
                zoom: 8, // 缩放等级
                attributionControl: false, // 版权控件
                zoomControl: true //缩放控件
            });
            L.tileLayer(
                // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://t7.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=f4d0553a23372a2f48c74851c7e46f4d",
                {
                    maxZoom: 16,

                }
            ).addTo(this.map)
            L.tileLayer(
                "https://t1.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=f4d0553a23372a2f48c74851c7e46f4d",
                {
                    maxZoom: 16,

                }
            ).addTo(this.map)
        }
    }
}

</script>


<style scoped>
/*

*/
@import "leaflet/dist/leaflet.css";

.container {
    width: 100%;
    height: 100vh;
    margin-top: -60px;
}

.container #vue-leaflet {
    width: 100%;
    height: 100%;
}
</style>




